var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardItem',{attrs:{"lg":4,"minHeight":false,"cardHeaderMb":0,"dashboardItemLabel":_vm.dashboardItemLabel,"dashboardItemLabelValue":_vm.dashboardItemLabelValue},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('div',{staticClass:"overflow-custom list-min-max"},[_c('b-table',{attrs:{"show-empty":"","stacked":"lg","items":_vm.sections,"fields":_vm.fields,"id":"manual-devices-dashboard-table","tbody-class":"table-body","data-test":"manual-devices-overview","primary-key":"projects-alerts-table","borderless":"","thead-class":"custom-table-header","thead-tr-class":"custom-right-inset-shadow text-small","tbody-tr-class":"table-items-row custom-right-inset-shadow "},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
              maxWidth: field.key !== 'projectName' ? '80px' : ''
            })})})}},{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center text-muted"},[_vm._v(_vm._s(_vm.$t("utils.table.emptyDeviceTableDashboard")))])]},proxy:true},{key:"emptyfiltered",fn:function(){return [_c('h4',{staticClass:"text-center text-muted"},[_vm._v(_vm._s(_vm.$t("utils.table.emptyDeviceTableDashboard")))])]},proxy:true},{key:"cell(boardStatus)",fn:function(ref){
            var item = ref.item;
return [_c('b-badge',{staticClass:"p-2 d-flex align-items-center justify-content-center custom-cursor-pointer",staticStyle:{"min-width":"50px"},attrs:{"variant":item.boardStatus === 'SUPPORT'
                ? 'info'
                : item.boardStatus === 'ALERT'
                ? 'danger'
                : item.boardStatus === 'WARNING'
                ? 'pending'
                : item.boardStatus === 'WAITING'
                ? 'inactive'
                : 'success'},on:{"click":function($event){return _vm.$emit('handleOpenInfoModal', {
                projectId: item.projectId,
                sectionId: item.sectionId,
                devicePosition: item.boardPosition
              })}}},[_vm._v(" "+_vm._s(item.boardStatus)+" ")])]}},{key:"cell(projectName)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"custom-text-overflow-new-line"},[_c('b-link',{staticClass:"text-success",attrs:{"to":("/projects/" + (item.projectId))}},[_vm._v(" "+_vm._s(item.projectName)+" ")])],1)]}},{key:"cell(kilometrage)",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formattedPosition(item.kilometrage)))])]}},{key:"cell(serial_number)",fn:function(ref){
              var item = ref.item;
return [(item.mainboardUrl && _vm.getUserAccess.Access.VIEW.dashboard.mainboardLinks)?_c('b-link',{staticClass:"text-success",attrs:{"href":item.mainboardUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.mainboardId)+" "),_c('i',{staticClass:"fa fa-external-link float-right"})]):_c('span',[_vm._v(_vm._s(item.mainboardId))])]}},{key:"cell(display)",fn:function(ref){
              var item = ref.item;
return [_c('img',{class:'scale-down',attrs:{"src":_vm.getFilenameImgSrc(item.displayType.name, item.fileName),"alt":("" + (item.fileName ? item.fileName.replace('.gif', '').replace('.bmp', '') : '')),"width":("" + (item.displayType.width)),"height":("" + (item.displayType.height))}})]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }