
/**
 * Users stats from the system
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import DashboardItem from "@/components/shared/DashboardItem.vue";

@Component({
  name: "UsersStats",
  components: {
    DashboardItem
  },
  computed: {
    ...mapGetters([
      "getAllUsers",
      "userListGetActiveUsers",
      "userListGetAllBannedUsers",
      "userListGetAllPendingUsers",
      "userListGetAllInactiveUsers"
    ])
  },
  methods: {
    ...mapActions(["getAllUserFromBackEnd"])
  }
})
export default class UsersStats extends Vue {
  /*----------  Vuex  ----------*/
  readonly getAllUsers!: number;
  readonly userListGetActiveUsers!: number;
  readonly userListGetAllBannedUsers!: number;
  readonly userListGetAllPendingUsers!: number;
  readonly userListGetAllInactiveUsers!: number;

  private getAllUserFromBackEnd!: Function;
  get formatedUserStats() {
    let localUserData = {
      active: this.userListGetActiveUsers,
      banned: this.userListGetAllBannedUsers,
      pending: this.userListGetAllPendingUsers,
      inactive: this.userListGetAllInactiveUsers
    };
    let translatedUsersStats: { [label: string]: number } = {};
    Object.keys(localUserData).forEach((item) => {
      let label: string = this.$t(`users.table.badge.${item}Text`).toString();
      translatedUsersStats[label] = (localUserData as any)[item];
    });
    return translatedUsersStats;
  }

  /*----------  Vuejs life-cycles   ----------*/
  mounted() {
    this.getAllUserFromBackEnd();
  }
}
