
/**
 *  UsersHealth All the users stats from the system
 *  Users states
 *  Users current status
 *  Users latest activity
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Vue, Component } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import { UsersSessionDetails } from "@/model/Dashboard/ModelUsersHealth";

import UserCard from "@/components/dashboard/Users/UserCard.vue";
import DashboardItem from "@/components/shared/DashboardItem.vue";

@Component({
  name: "UsersHealth",
  computed: {
    ...mapGetters("Dashboard", {
      USERMANAGER_USERS_STATS: "GetUsersStats"
    })
  },
  methods: {
    ...mapActions("Dashboard", {
      GET_USERS_STATS_DASHBOARD: "GET_USERS_STATS_DASHBOARD"
    })
  },
  components: {
    UserCard,
    DashboardItem
  }
})
export default class UsersHealth extends Vue {
  readonly USERMANAGER_USERS_STATS!: Array<UsersSessionDetails>;
  private GET_USERS_STATS_DASHBOARD!: Function;

  usersHealthInterval: Array<any> = [];

  /*----------  Vue life cycles  ----------*/
  async created() {
    let getUserHealth = await this.GET_USERS_STATS_DASHBOARD();
    if (getUserHealth.status === 200) {
      this.createIntervalGetProjectHealth();
    }
  }
  beforeDestroy() {
    console.log("#### CLEAN INTERVAL FOR USERS HEALTH THE DASHBOARD ######");
    this.clearAllIntervalsBeforeLeave();
  }
  /*----------  Methods  ----------*/
  private async clearAllIntervalsBeforeLeave() {
    let promises = this.usersHealthInterval.map(async (intervalId: number) => {
      await clearInterval(intervalId);
    });
    await Promise.all(promises);
  }

  private async createIntervalGetProjectHealth() {
    let timeToGetProjectsHealth = 1000 * 60;

    const newHealthInterval = setInterval(async () => {
      await this.GET_USERS_STATS_DASHBOARD();
    }, timeToGetProjectsHealth);

    this.usersHealthInterval.push(newHealthInterval);
  }
}
