
/**
 * Description Inventory health information
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";

import DashboardItem from "@/components/shared/DashboardItem.vue";
@Component({
  name: "InventoryHealth",
  components: {
    DashboardItem
  },
  computed: {
    ...mapGetters("Dashboard", {
      INVENTORY_DEVICE_STATE_METRIC_HEALTH: "GetDeviceStateMetric",
      INVENTORY_DEVICE_STATE_COLORS: "GetDeviceStateColors",
      INVENTORY_TOTAL_DEVICES: "GetTotalDeviceCount"
    })
  },
  methods: {
    ...mapActions("Dashboard", {
      GET_INVENTORY_STATE_DASHBOARD: "GET_INVENTORY_DEVICE_STATE_DASHBOARD"
    })
  }
})
export default class InventoryHealth extends Vue {
  /*----------  VUex  ----------*/
  readonly INVENTORY_DEVICE_STATE_METRIC_HEALTH!: object;
  readonly INVENTORY_DEVICE_STATE_COLORS!: Array<string>;
  readonly INVENTORY_TOTAL_DEVICES!: number;

  private GET_INVENTORY_STATE_DASHBOARD!: Function;

  /*----------  vue life cycles  ----------*/
  async created() {
    await this.GET_INVENTORY_STATE_DASHBOARD();
  }
}
