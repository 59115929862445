
/**
 * The table overview for the states of all the devices
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Component, Mixins, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";

import DashboardItem from "../shared/DashboardItem.vue";
import ManualDevice from "@/model/ModelDashBoardSections";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "ManualDeviceTable",
  components: {
    DashboardItem
  },
  computed: {
    ...mapGetters(["getUserAccess"])
  }
})
export default class ManualDeviceTable extends Mixins(KmFormatted) {
  readonly getUserAccess!: UserAccess;
  @Prop({ type: String, default: "" }) dashboardItemLabel!: string;
  @Prop({ type: Number, default: 0 }) dashboardItemLabelValue!: number;
  @Prop({ type: Array, default: () => [] }) sections!: ManualDevice[];
  @Prop({ type: Array, default: () => ["boardStatus", "projectName", "kilometrage", "serial_number", "display"] })
  headerFields!: string[];

  fields: Array<any> = [];

  webUrl = process.env.VUE_APP_WEB_URL;
  created() {
    this.fields = this.headerFields.map((field: string) => ({
      key: field,
      label: this.translateTableHeaderLabel(field),
      sortable: true
    }));
  }

  translateTableHeaderLabel(key: string) {
    return this.$t(`dashboard.metricsProjects.tableLabels.${key}`);
  }

  getFilenameImgSrc(displaySize: string, filename: string) {
    if (filename) {
      return `${this.webUrl}/img/${this.getBoardSize(displaySize)}/${filename}`;
    } else {
      return `${this.webUrl}/img/64x96/noPicture.jpg`;
    }
  }

  getBoardSize(boardType: any) {
    if (!boardType) {
      return "48x48";
    }
    switch (boardType) {
      case "small":
        return "48x48";
      case "medium":
        return "48x80";
      case "large":
        return "64x96";
      case "vwa":
        return "64x144";
      default:
        return "48x48";
    }
  }
}
