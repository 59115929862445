
/**
 * Description User card information
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { UsersSessionDetails } from "@/model/Dashboard/ModelUsersHealth";

@Component({
  name: "UserCard"
})
export default class UserCard extends Vue {
  @Prop({ required: true, type: Object }) user!: UsersSessionDetails;
}
