
/**
 * The table overview for the states of all the devices
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Component, Mixins, Prop } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";

import DashboardItem from "../shared/DashboardItem.vue";
import DashboardSections from "@/model/ModelDashBoardSections";
import UserAccess from "@/model/User/UserAccess";

interface Pagination {
  currentPage: number;
  totalPages: number;
  nextPage: number;
}

@Component({
  name: "AlarmTableOverview",
  components: {
    DashboardItem
  },
  methods: {
    ...mapActions("Dashboard", {
      GET_ALL_SECTIONS_STATUS: "GET_ALL_PROJECTS_SECTIONS_DASHBOARD"
    })
  },
  computed: {
    ...mapGetters(["getUserAccess"])
  }
})
export default class AlarmTableOverview extends Mixins(KmFormatted) {
  private GET_ALL_SECTIONS_STATUS!: Function;
  readonly getUserAccess!: UserAccess;

  @Prop({ type: Array, default: () => [] }) sections!: DashboardSections[];
  @Prop({
    type: Object as () => Pagination,
    default: () => {}
  })
  sectionPagination!: Pagination;

  @Prop({ type: Number, default: 20 }) perPage!: number;

  fields: Array<any> = [
    {
      key: "boardStatus",
      label: this.translateTableHeaderLabel("boardStatus"),
      sortable: true
    },
    {
      key: "connectionStatus",
      label: this.translateTableHeaderLabel("connectionStatus"),
      sortable: false
    },
    {
      key: "reason",
      label: this.translateTableHeaderLabel("reason"),
      sortable: false
    },
    {
      key: "mainProjectName",
      label: this.translateTableHeaderLabel("mainProjectName"),
      sortable: false
    },
    {
      key: "projectName",
      label: this.translateTableHeaderLabel("projectName"),
      sortable: false
    },
    {
      key: "sectionTitle",
      label: this.translateTableHeaderLabel("sectionTitle"),
      sortable: false
    },
    {
      key: "kilometrage",
      label: this.translateTableHeaderLabel("kilometrage"),
      sortable: false
    },
    {
      key: "sectionDirectionTo",
      label: this.translateTableHeaderLabel("direction"),
      sortable: false
    },
    {
      key: "serial_number",
      label: this.translateTableHeaderLabel("serial_number"),
      sortable: false
    },
    {
      key: "router_serial_number",
      label: this.translateTableHeaderLabel("router_serial_number"),
      sortable: false
    },
    {
      key: "ping",
      label: this.translateTableHeaderLabel("ping"),
      sortable: false
    }
  ];
  currentPage: number = 1;

  translateTableHeaderLabel(key: string) {
    return this.$t(`dashboard.metricsProjects.tableLabels.${key}`);
  }

  async HadleNextPageAction(e: number) {
    let getNewStatus = await this.GET_ALL_SECTIONS_STATUS(e - 1);
    if (getNewStatus.status === 200) {
      this.currentPage = e;
    }
  }
}
