var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardItem',{attrs:{"lg":12,"minHeight":false,"cardHeaderMb":0,"dashboardItemLabel":_vm.$t('dashboard.metricsProjects.tableTitleHealth')},scopedSlots:_vm._u([{key:"headerValue",fn:function(){return [_c('span')]},proxy:true},{key:"data",fn:function(){return [_c('b-table',{attrs:{"show-empty":"","stacked":"lg","items":_vm.sections,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":1,"id":"sections-status-dashboard-table","tbody-class":"table-body","data-test":"project-health-overview","primary-key":"projects-alerts-table","borderless":"","thead-class":"custom-table-header","thead-tr-class":"custom-right-inset-shadow","tbody-tr-class":"table-items-row custom-right-inset-shadow "},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
            width:
              field.key === 'boardStatus' || field.key === 'connectionStatus'
                ? '125px'
                : field.key === 'reason'
                ? '350px'
                : ''
          })})})}},{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center text-muted"},[_vm._v(_vm._s(_vm.$t("utils.table.emptyTableDashboard")))])]},proxy:true},{key:"emptyfiltered",fn:function(){return [_c('h4',{staticClass:"text-center text-muted"},[_vm._v(_vm._s(_vm.$t("utils.table.emptyTableDashboard")))])]},proxy:true},{key:"cell(boardStatus)",fn:function(ref){
          var item = ref.item;
return [_c('b-badge',{staticClass:"p-2 d-flex align-items-center justify-content-center",staticStyle:{"min-width":"125px"},attrs:{"variant":item.supportEnabled ? 'info' : item.boardStatus === 'ALERT' ? 'danger' : 'pending'}},[_vm._v(" "+_vm._s(item.supportEnabled ? "SUPPORT" : item.boardStatus)+" ")])]}},{key:"cell(connectionStatus)",fn:function(ref){
          var item = ref.item;
return [_c('b-badge',{staticClass:"p-2 d-flex align-items-center justify-content-center",staticStyle:{"min-width":"125px"},attrs:{"variant":item.connectionStatus ? 'active' : 'banned'}},[_vm._v(" "+_vm._s(item.connectionStatus ? "Online" : "Offline")+" ")])]}},{key:"cell(reason)",fn:function(ref){
          var item = ref.item;
return [(!item.reasons || item.reasons.length === 0)?_c('div',{staticClass:"text-wrap custom-cursor-pointer",on:{"click":function($event){return _vm.$emit('handleOpenInfoModal', {
              projectId: item.projectId,
              sectionId: item.sectionId,
              devicePosition: item.boardPosition
            })}}},[(item.supportEnabled)?_c('b-alert',{staticClass:"p-0 text-center m-0",staticStyle:{"min-height":"26px"},attrs:{"show":"","variant":"info"}},[_vm._v(" "+_vm._s(_vm.$t("project.section.status.support.support_mode"))+" ")]):_c('b-alert',{staticClass:"p-0 text-center m-0",staticStyle:{"min-height":"26px"},attrs:{"show":"","variant":"banned"}},[_vm._v(" "+_vm._s(_vm.$t("project.section.status.noStatus.no_reason"))+" ")])],1):_c('div',_vm._l((item.reasons),function(reason){return _c('div',{key:reason.id,staticClass:"text-wrap custom-cursor-pointer",class:{ 'mb-1': item.reasons.length > 1 },on:{"click":function($event){return _vm.$emit('handleOpenInfoModal', {
                projectId: item.projectId,
                sectionId: item.sectionId,
                devicePosition: item.boardPosition
              })}}},[(reason.label.split('.')[3] !== 'ok')?_c('b-alert',{staticClass:"p-0 text-center m-0",staticStyle:{"min-height":"26px"},attrs:{"show":"","variant":reason.label.split('.')[3] === 'warning' ? 'pending' : 'banned'}},[_vm._v(" "+_vm._s(_vm.$t(reason.label, { battery: item.batteryTerminalVoltage }))+" ")]):_vm._e(),(reason.label.split('.')[3] === 'ok' && item.supportEnabled)?_c('b-alert',{staticClass:"p-0 text-center m-0",staticStyle:{"min-height":"26px"},attrs:{"show":"","variant":"info"}},[_vm._v(" "+_vm._s(_vm.$t(reason.label, { battery: item.batteryTerminalVoltage }))+" ")]):_vm._e()],1)}),0)]}},{key:"cell(projectName)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"custom-text-overflow"},[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-success",attrs:{"to":("/projects/" + (item.projectId))}},[_vm._v(" "+_vm._s(item.projectName)+" ")])],1)]}},{key:"cell(sectionTitle)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"custom-text-overflow"},[_vm._v(" "+_vm._s(item.sectionDescription || item.sectionTitle)+" ")])]}},{key:"cell(mainProjectName)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"custom-text-overflow"},[_vm._v(" "+_vm._s(item.mainProjectName)+" ")])]}},{key:"cell(kilometrage)",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formattedPosition(item.kilometrage)))])]}},{key:"cell(sectionDirectionTo)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"custom-text-overflow"},[_vm._v(" "+_vm._s(item.directionTo)+" ")])]}},{key:"cell(serial_number)",fn:function(ref){
              var item = ref.item;
return [(item.mainboardUrl && _vm.getUserAccess.Access.VIEW.dashboard.mainboardLinks)?_c('b-link',{staticClass:"text-success",attrs:{"href":item.mainboardUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.mainboardId)+" "),_c('i',{staticClass:"fa fa-external-link float-right"})]):_c('span',[_vm._v(_vm._s(item.mainboardId))])]}},{key:"cell(router_serial_number)",fn:function(ref){
              var item = ref.item;
return [(item.routerUrl && _vm.getUserAccess.Access.VIEW.dashboard.mainboardLinks)?_c('b-link',{staticClass:"text-success custom-text-overflow",attrs:{"href":item.routerUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.serialNumber)+" "),_c('i',{staticClass:"fa fa-external-link float-right"})]):_c('span',[_vm._v(_vm._s(item.serialNumber))])]}},{key:"cell(ping)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"custom-text-overflow"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('project.section.sectionState.latencyMainboard')}},[_vm._v(" "+_vm._s(item.latencyMainboard)+" ms |")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('project.section.sectionState.latencyRouter')}},[_vm._v(" "+_vm._s(item.latencyRouter)+" ms ")])])]}}])}),(_vm.sectionPagination.totalPages > 1)?_c('b-pagination',{staticClass:"custom-pagination",attrs:{"first-class":"previus-button","last-class":"previus-button","prev-class":"previus-button","next-class":"previus-button","align":"center","total-rows":_vm.sectionPagination.totalPages,"per-page":1},on:{"input":_vm.HadleNextPageAction},scopedSlots:_vm._u([{key:"first-text",fn:function(){return [_c('i',{staticClass:"fa fa-angle-left"})]},proxy:true},{key:"prev-text",fn:function(){return [_c('i',{staticClass:"fa fa-angle-double-left"})]},proxy:true},{key:"next-text",fn:function(){return [_c('i',{staticClass:"fa fa-angle-double-right"})]},proxy:true},{key:"last-text",fn:function(){return [_c('i',{staticClass:"fa fa-angle-right"})]},proxy:true}],null,false,1057772687),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }